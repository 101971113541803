/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

:root {
  --blue-green: #1a7d8f;
  --very-light-pink: #dddddd;
  --white: #ffffff;
  --black-14: rgba(0, 0, 0, 0.14);
  --black-two-14: rgba(0, 0, 0, 0.14);
  --orangey-yellow: #f5a623;
  --poop-brown: #7c4d00;
  --black: #000000;
  --off-white: #f0f6e8;
  --very-light-pink-two: #f7e4e4;
  --orangey-yellow-30: rgba(245, 166, 35, 0.3);
  --pale-grey: #f7feff;
  --greyish-brown: #4a4a4a;
  --greyish-brown-two: #545454;
  --brownish-grey: #696969;
  --twilight-blue: #0b4c58;
  --navy: #012e36;
  --black-8: rgba(0, 0, 0, 0.08);
  --white-two: #fafafa;
  --apple-green-60: rgba(126, 211, 33, 0.6);
  --tomato-60: rgba(231, 21, 19, 0.6);
  --blue-green-30: rgba(26, 125, 143, 0.3);
  --blue-green-two: #137f93;
  --dirty-blue: #4b9fae;
  --black-17: rgba(0, 0, 0, 0.17);
  --brown-grey: #9b9b9b;
  --very-light-pink-three: #d8d8d8;
  --brown-grey-28: rgba(155, 155, 155, 0.28);
  --dirty-blue-20: rgba(75, 159, 174, 0.2);
  --black-21: rgba(0, 0, 0, 0.21);
  --brown-grey-40: rgba(155, 155, 155, 0.4);
  --dirty-blue-two: #32889b;
  --brownish-grey-two: #5e5e5e;
  --brown-grey-two: #aaaaaa;
  --very-light-pink-four: #e1e1e1;
  --very-light-pink-five: #dcdcdc;
  --dirty-blue-three: #4792a7;
  --wheat-42: rgba(255, 226, 119, 0.42);
  --pale: #fdeccf;
  --wheat: #f9ca7b;
  --spring-green: #b2e57a;
  --salmon: #f17371;
  --very-light-pink-six: #c3c3c3;
  --greenish-blue: #11758d;
  --brown-grey-three: #979797;
  --apple-green: #7ed321;
  --pale-peach: #f6dcac;
  --mud-brown: #694710;
  --brownish-grey-three: #757575;
  --black-two-8: rgba(0, 0, 0, 0.08);
}
