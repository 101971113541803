.custom-collapse-arrow svg {
  transform-origin: 4px 10px;
}

.offer-ghost-collapse {
  .ant-collapse-arrow {
    color: #11758d !important;
    left: 0px !important;
  }
  .ant-collapse-header {
    padding: 0px 20px !important;
    font-size: 16px;
    font-weight: 500;
    color: var(--black);
    a {
      font-weight: 500;
      margin-left: 10px;
      font-size: 12px;
    }
    & > div {
      display: flex;
      align-items: center;
    }
  }
  .ant-collapse-item {
    margin-top: 10px;
    &:first-child {
      margin-top: 0px;
    }
  }
  .ant-collapse-content-box {
    padding: 16px 0px 0px;
  }
  @media screen and (max-width: 576px) {
    .ant-collapse-header {
      font-size: var(--font-size-xs-middle);
    }
    .ant-collapse-content-box {
      padding: 7px 0px 0px !important;
    }
  }
}
