.offer-modal {
  .ant-modal-header {
    margin: 0px 20px;
    padding: 33px 0px 12px;

    .ant-modal-title {
      font-size: 18px;
      font-weight: 600;
      color: var(--black);
    }
  }
  .ant-modal-body {
    padding: 14px 20px;
  }
  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    border: none;
    padding: 0px 40px 33px;

    button {
      margin-left: 22px;
      width: 86px;
    }
  }
}
