.tab-radio-no-underline {
  .ant-radio-button-wrapper {
    @apply border-0 text-sm px-px mr-12 bg-#FAFAFA pt-1 font-normal text-#4E4E4E;

    &:hover {
      @apply bg-transparent text-#5E5E5E;
    }
    &:focus-within {
      box-shadow: none !important;
    }

    &:first-child,
    &:last-child {
      @apply border-l-0 border-r-0 rounded-none;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
  .ant-radio-button-wrapper:not(:first-child)::before {
    content: none;
  }
  .ant-radio-button-wrapper-checked {
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    &,
    &:active,
    &:hover {
      @apply font-bold bg-#FAFAFA text-#5E5E5E;
    }
  }
}

.tab-radio {
  .ant-radio-button-wrapper {
    @apply border-0 text-sm px-px mr-12 bg-transparent text-primary h-6;

    &:focus-within {
      box-shadow: none !important;
    }

    &:first-child,
    &:last-child {
      @apply border-l-0 border-r-0 rounded-none;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
  .ant-radio-button-wrapper:not(:first-child)::before {
    content: none;
  }
  .ant-radio-button-wrapper-checked {
    @apply border-b-2 font-bold;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    @apply border-b-2 bg-transparent;
  }
}
