.offer-tab-container {
  .ant-tabs {
    .ant-tabs-nav-wrap {
      overflow: visible !important;
    }
    .ant-tabs-ink-bar {
      box-shadow: 0 2px 10px 0 var(--dirty-blue);
      border-radius: 2px;
    }
    .ant-tabs-nav {
      padding-left: 15px;
      z-index: 1;

      &::before {
        bottom: 1px !important;
      }
    }
    .ant-tabs-tab {
      margin-right: 14px;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
  }
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs-tab {
    font-size: 12px;
  }
}

.my-tab {
  @apply h-full !important;
  .ant-tabs-nav {
    @apply mb-0 pl-5 before:content-none !important;
  }
  .ant-tabs-tab {
    @apply bg-#E7EDEA text-#5E5E5E rounded-t-full h-8 px-7 ml-0 -mr-1 font-bold text-xs !important;
  }
  .ant-tabs-tab-active {
    @apply bg-white z-10 !important;
  }
  .ant-tabs-ink-bar {
    @apply hidden !important;
  }
  .ant-tabs-content-holder {
    @apply bg-white rounded-md  overflow-hidden !important;
  }
  .ant-tabs-content {
    @apply h-full;
    .ant-tabs-tabpane-active > * {
      @apply min-h-full;
    }
  }
}
.button-tab {
  .ant-tabs-nav {
    margin-top: 23px !important;
  }
  .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-nav-wrap {
    @apply flex justify-center;

    .ant-tabs-tab {
      @apply mx-0 h-[30px] w-[100px] justify-center border-#E7EDEA bg-transparent rounded-none text-primary text-xs !important;
      &:first-child {
        @apply rounded-l-[20px] !important;
      }
      &:nth-last-child(2) {
        @apply rounded-r-[20px] !important;
      }
    }
    .ant-tabs-tab-active {
      @apply bg-#E7EDEA  !important;
      .ant-tabs-tab-btn {
        @apply font-normal !important;
      }
    }
  }
}
