.mini-form {
  .ant-form-item-label {
    display: none !important;
  }
  .ant-form-item-control-input {
    min-height: 23px !important;
  }
  .ant-select-selector,
  .ant-input-affix-wrapper {
    height: 23px !important;
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    line-height: 23px !important;
  }
  input {
    height: 20px !important;
  }
}
.hide-form-lable {
  .ant-form-item-label {
    display: none !important;
  }
}

@media screen and (max-width: 576px) {
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 1 !important;
  }
}

.filter-form {
  .ant-form-item {
    @apply m-0 min-h-[45px] flex-nowrap !important;
  }
  .ant-form-item-label {
    @apply max-w-[80px] bg-[#FAFAFA] text-left flex items-center pl-4  !important;
    label {
      @apply text-primary text-sm !important;
    }
  }
  .ant-form-item-control {
    @apply ml-5 justify-center flex-wrap flex-1 !important;
  }

  .ant-radio-group {
    @apply flex space-x-2 flex-wrap !important;
  }
  .ant-radio-button-wrapper {
    @apply border-none h-5 rounded leading-none inline-flex items-center px-[6px] !important;

    &:focus-within {
      box-shadow: none !important;
    }
    &:first-child,
    &:last-child {
      @apply border-l-0 border-r-0 rounded-none;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
  .ant-radio-button-wrapper:not(:first-child)::before {
    content: none;
  }
  .ant-radio-button-wrapper-checked {
    @apply bg-#FC9F4A text-white !important;
  }

  .ant-slider-handle {
    @apply border-#FC9F4A  !important;
  }
  .ant-slider-track {
    @apply bg-#FC9F4A  !important;
  }

  @screen md {
    .ant-form-item-label {
      @apply min-w-[180px] !important;
    }
  }
}

.fiverr-form {
  .ant-form-item-label > label {
    @apply text-sm w-[140px] text-#5E5E5E !important;
  }
}

.ps-draft-form {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
}
