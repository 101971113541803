@import '//at.alicdn.com/t/font_1635620_fco230px2ku.css';
@import './colors.css';
@import './variables.css';
@import './antd/index.css';

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.ant-table {
  @media screen and (max-width: 576px) {
    table {
      width: 100% !important;
    }
    .ant-descriptions-view {
      table {
        padding-left: 10px;
      }
      th,
      td {
        width: 50% !important;
        display: inline-block;

        &.ant-descriptions-item-label {
          border: none;
        }
        &.ant-descriptions-item-content {
          border-left: 1px solid #e8e8e8;
          padding-left: 5px;
        }
      }
    }
  }
}

.input-item-row {
  display: flex;
  flex-direction: row;
}
.input-item-column {
  display: flex;
  flex-direction: column;

  .ant-form-item-label {
    text-align: left;
  }
  .ant-form-item-control {
    flex: auto;
  }
}
.item-column-container {
  .ant-form-item {
    display: flex;
    flex-direction: column;

    .ant-form-item-label {
      text-align: left;
    }
    .ant-form-item-control {
      flex: auto;
    }
  }
}
.item-row-container {
  .ant-form-item {
    display: flex;
    flex-direction: row;

    .ant-form-item-label {
      text-align: left;
    }
    .ant-form-item-control {
      flex: auto;
    }
  }
}

a {
  color: var(--blue-green);

  &:active {
    color: var(--twilight-blue);
  }
}
.offer-table {
  .ant-pagination {
    .ant-pagination-prev.ant-pagination-disabled {
      display: none;
    }
    .ant-pagination-item {
      height: 18px !important;
      border-radius: 4px;
      border: solid 1px var(--blue-green);

      a {
        padding: 0px;
        height: 100%;
        margin: 0px;
        font-size: 13px;
        line-height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &,
      a {
        min-width: 19px !important;
      }
    }
    .ant-pagination-next,
    .ant-pagination-prev {
      .ant-pagination-item-link {
        display: flex;
        align-items: center;
      }
    }
  }
}

.ant-tooltip-content {
  font-size: 12px;
}
.ant-form {
  .ant-select-selector,
  input,
  textarea,
  .ant-form-item-explain,
  .ant-form-item-extra {
    font-size: 12px !important;
  }
  &.ant-form-vertical .ant-form-item-label {
    padding-bottom: 1px;
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    margin-top: 3px;
    margin-bottom: 0px;
  }
}

.ant-alert.ant-alert-info {
  font-size: 12px;
  color: var(--black);
  background-color: var(--wheat-42);
  padding: 5px 10px;

  .ant-alert-close-icon {
    top: 10px;
  }

  .ant-alert-message {
    color: #497b89;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}
