.small .ant-rate-star:not(:last-child) {
  margin-right: 1px;
}

.star-rate .ant-rate-star-first {
  display: none;
}
.star-rate .ant-rate-star:not(:last-child) {
  margin-right: 0px;
}
