.ant-menu {
  .ant-btn-background-ghost {
    &::after {
      display: none !important;
    }
  }
}

.custom-submenu {
  .ant-menu-submenu-title {
    @apply flex justify-center px-0 h-[50px];
    button.ant-btn {
      @apply shadow-none border-none py-0 px-[10px] font-semibold text-base mt-2 !important;
    }
    i.anticon {
      @apply mr-0 ml-[6px];
    }
  }

  &.ant-menu-submenu-open {
    @apply bg-white !important;
    button {
      @apply text-primary !important;
    }
  }
}
@media screen and (min-width: 768px) {
  .custom-submenu {
    @apply mx-4 p-0 rounded-t-[20px] w-[104px] text-center top-2  !important;
  }
}
.ant-menu-submenu-title {
  .anticon-menu {
    @apply relative top-[-2px];
  }
}

@media screen and (min-width: 768px) {
  .custom-submenu-popup {
    .ant-menu-sub {
      @apply bg-white !important;
    }
    ul {
      display: grid;
      box-shadow: 0px 4px 8px 5px rgba(73, 123, 137, 0.03) !important;
      @apply rounded-t-none  before:content-none after:content-none  !important;

      a {
        @apply font-bold text-#5E5E5E !important;
      }
      .ant-menu-item:hover,
      .ant-menu-item:active,
      .ant-menu-item-selected {
        a {
          @apply text-primary  !important;
        }
      }
    }
  }
  .ant-menu-hidden {
    @apply hidden !important;
  }
  .myapply ul {
    @apply grid-cols-4;
  }
  .db ul {
    @apply grid-cols-3;
  }
  .post ul {
    @apply grid-cols-3;
  }
  .vip ul {
    @apply grid-cols-2;
  }
}

.ant-menu-dark .ant-menu-sub {
  @apply bg-white !important;
  button {
    @apply text-#5E5E5E !important;
  }
  .ant-menu-item a {
    @apply text-#5E5E5E !important;
  }
  .logo {
    @apply hidden;
  }
}
