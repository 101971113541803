.offer-table {
  font-family: trebuchet MS, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

  table {
    border-spacing: 0px 3px !important;
    font-size: 12px;
  }
  .ant-table-thead th {
    background: var(--white);
    border: none;
    font-weight: 600;
    color: var(--black);
  }
  .ant-table-cell {
    background: var(--white-two);
    border: none;
  }
  .ant-table-row:hover .ant-table-cell {
    background: var(--pale-grey);
  }
  .ant-btn-link {
    font-weight: 400;
    color: var(--blue-green);
  }
  .success-result .result {
    background-color: var(--off-white) !important;
    border-left: 2px solid var(--apple-green-60);
  }
  .rej-result .result {
    background-color: var(--very-light-pink-two) !important;
    border-left: 2px solid var(--tomato-60);
  }
  .ant-table-thead {
    th.ant-table-column-sort,
    th.ant-table-column-has-sorters:hover {
      background: none;
    }
  }
  @media screen and (max-width: 576px) {
    table {
      font-size: var(--font-size-xs-base);
    }
    th {
      padding: 3px 0px !important;
    }
    .ant-table-tbody > tr {
      td {
        padding: 3px 2px !important;

        &.ant-table-row-expand-icon-cell {
          padding-left: 10px !important;
        }
      }
    }
  }
}

.gray-table {
  .ant-table-thead > tr > th {
    @apply bg-#FAFAFA border-t border-b border-#DCDCDC text-sm text-#5E5E5E font-bold h-10 py-0;
  }
  .ant-table-tbody > tr > td {
    @apply text-sm text-#4E4E4E border-#DCDCDC py-3;
  }
  .ant-table-container table > thead > tr:first-child th:first-child,
  .ant-table-container table > thead > tr:first-child th:last-child {
    @apply rounded-none;
  }
}
.mobile-table {
  .ant-table-thead > tr > th {
    @apply bg-transparent border-none text-base text-#999 font-normal whitespace-nowrap;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    @apply py-2 px-1;
  }
}

.db-table {
  .ant-table-thead > tr > th {
    @apply h-[50px] py-0 bg-#ECF1F1 text-primary font-bold text-sm;
  }
  .ant-table-tbody > tr > td {
    @apply border-none h-[60px] py-0 text-sm;
  }
  .ant-table-tbody > tr:nth-child(even) > td {
    @apply bg-#FAFAFA;
  }
  .ant-table-container table > thead > tr:first-child th:first-child,
  .ant-table-container table > thead > tr:first-child th:last-child {
    @apply rounded-none;
  }
  &.small {
    .ant-table-tbody > tr > td {
      @apply h-[45px];
    }
  }
}
@media (max-width: 640px) {
  .sm-td-px-0 {
    .ant-table-cell {
      @apply px-0;
    }
  }
}

.my-table {
  .ant-table-thead > tr > th {
    @apply h-[40px] py-0 bg-#F4F6F5 text-#5E5E5E font-bold text-xs;
  }
  &.recommend-table .ant-table-thead > tr > th {
    @apply px-1;
  }
  .ant-table-tbody > tr > td {
    @apply border-none h-[50px] py-0 text-xs;
  }
  &.recommend-table .ant-table-tbody > tr > td {
    @apply px-1;
  }
  .ant-table-tbody > tr:nth-child(even) > td {
    @apply bg-#FAFAFA;
  }
  .ant-table-container table > thead > tr:first-child th:first-child,
  .ant-table-container table > thead > tr:first-child th:last-child {
    @apply rounded-none;
  }
  &.small {
    .ant-table-tbody > tr > td {
      @apply h-[45px];
    }
  }
}

.ant-table-cell:empty {
  padding: 0px;
}
.target-row-1 .target-column {
  @apply bg-#FFA500/10 text-#FFA500 !important;
}
.target-row-2 .target-column {
  @apply bg-#4FC434/10 text-#18A00F !important;
}
.target-row-3 .target-column {
  @apply bg-#990001/10 text-#CB4141 !important;
}
