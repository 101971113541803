/* Write your own custom base styles here */

@tailwind base;
a {
  color: #497b89;
}

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

.fade {
  -webkit-mask-image: linear-gradient(180deg, #000 50%, transparent);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.text-shadow {
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.8);
}

.title {
  @apply font-semibold lg:text-lg;
}
.bg-grid {
  background: #ffd394;
  background-image: linear-gradient(rgba(255, 255, 255, 0.3) 1px, transparent 0),
    linear-gradient(90deg, rgba(255, 255, 255, 0.3) 1px, transparent 0);
  background-size: 15px 15px, 15px 15px;
}
