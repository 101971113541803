.offer-small-alert.ant-alert-banner {
  @apply bg-#E7EDEA text-#5E5E5E text-xxs pl-6;
  max-width: 367px;
  height: 26px;
  display: flex;
  align-items: center;
  font-size: 12px;

  &.ant-alert-no-icon {
    padding-left: 10px;
  }
  &.warning {
    background-color: var(--wheat-42);
  }

  .ant-alert-icon {
    left: 10px;
    top: 8px;
  }
  .ant-alert-close-icon {
    top: 5px;
    right: 13px;
  }

  a {
    @apply px-1;
  }

  .anticon-exclamation-circle {
    @apply text-#5E5E5E;
  }
  .anticon-check-circle {
    color: var(--apple-green);
  }

  @media screen and (max-width: 576px) {
    & {
      font-size: var(--font-size-xs-small);
    }
  }
}
